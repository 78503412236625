/* eslint-disable @next/next/no-img-element */
"use client";
import React from "react";

const FrontendUI = (props) => {
    const { data } = props;
    return (
        <>
            <div
                key={data?.id}
                className="rounded-[10px] p-5 w-[200px] md:w-[220px] xl:w-[200px] lg:w-[155px] h-[155px]"
                style={{
                    background: "rgba(56, 56, 56, 1)",
                }}
            >
                {data?.image && (
                    <img className="" src={data?.image} alt={data?.alt} />
                )}
                <h3 className="text-sm my-2">{data.title}</h3>
                <p
                    className="text-[9px]"
                    style={{
                        color: "rgba(231, 231, 231, 1)",
                    }}
                >
                    {data.discription}
                </p>
            </div>
        </>
    );
};

export default FrontendUI;
